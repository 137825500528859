.wrap-div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.eye-icon {
  color: #F4FCFF;
  font-size: 2rem;
  cursor: pointer;
  transition: all 0.3s ease;
  position: absolute;
  left: 5vw;
  top: 5vh;
  background-color: #609cb6;
  border-radius: 50%;
  border: solid #609cb6 5px;
}
.eye-icon:hover {
  transform: scale(1.1);
}

.eye-icon-down {
  color: #F4FCFF;
  font-size: 2em;
  cursor: pointer;
  transition: all 0.3s ease;
  position: absolute;
  left: 5vw;
  top: 5vh;
  background-color: #8DB8CC;
  border-radius: 50%;
  border: solid #8DB8CC 5px;
}

.eye-icon-down:hover {
  transform: scale(1.1);
}

.show-label {
  color: #609cb6;
  font-weight: 600;
  letter-spacing: 1px;
  font-size: 0.7em;
  text-align: center;
}

.list-wrap {
  background-color: #fff;
  border-radius: 0 0.7em 0.7em 0;
  padding: 2rem 0.1rem 1rem 0.1rem;
  -webkit-box-shadow: 2px 2px 4px #b5ccd7;
  box-shadow: 2px 2px 4px #b5ccd7;
  position: absolute;
  left: 0;
  top: calc(5vh + 3em);
  width: 20vw;
  text-align: center;
  color: rgb(100, 100, 100);
  max-height: 65vh;
  overflow-y: scroll;
  transition: all 0.5s ease;
  z-index: 100;
}

.hidden {
  position: absolute;
  left: -20vw !important;
  top: calc(5vh + 3em) !important;
  transition: all 0.5s ease;
}

@media (max-width: 1024px) {
  .list-wrap {
    width: 40vw;
  }
  .hidden {
    left: -40vw !important;
  }
}
@media (max-width: 660px) {
  .list-wrap {
    width: 60vw;
  }
  .hidden {
    left: -60vw !important;
  }
}

.item-kor {
  width: calc(50% - 10px);
  margin: 0 5px;
  display: inline-flex;
  justify-content: end;

  p {
    text-align: end;
  }
}

.item-eng {
  width: calc(50% - 10px);
  margin: 0 5px;
  display: inline-flex;
  justify-content: start;

  p {
    text-align: start;
  }
}
