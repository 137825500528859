#play-example, #mute-example {
  font-size: 1.1em;
  position: absolute;
  line-height: 1.8;
  left: -2em;
  top: 0.4em;
}

.inline-icon {
  cursor: pointer;
  color: #546e78;
  display: inline-block !important;
}

.hidden-icon {
  margin: 0.2em 1em 0 0;
  display: none !important;
}