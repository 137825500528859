.dialogue-left span {
	margin: 0 !important;
	display: inline-block;
}

.dialogue-right span {
	margin: 0 !important;
	display: inline-block;
}

#play-example, #mute-example {
	margin: 0;
	display: inline-block;
	margin: 0.2em 0.5em 0.2em 0;
}

.dialogue-left {
  transform: translatey(0px);
	max-width: 60%;
  background-color: #fff;
  padding: 1rem;
  border-radius: 0.7em;
  position: relative;
  box-shadow: 4px 4px 8px #b5ccd7;
  margin-bottom: 3rem;
	display: flex;
}
.dialogue-left span:after {
  transform: translatey(0px);
  content: ".";
  font-weight: bold;
  -webkit-text-fill-color: #fff;
  text-shadow: 2px 2px 4px #b5ccd7;
  text-align: left;
  font-size: 55px;
  width: 55px;
  height: 11px;
  line-height: 1rem;
  border-radius: 11px;
  background-color: #fff;
  position: absolute;
  display: block;
  bottom: -20px;
  left: 0;
  box-shadow: 2px 2px 4px #b5ccd7;
  z-index: -2;
}

.dialogue-right {
  transform: translatey(0px);
	max-width: 60%;
  background-color: #fff;
  padding: 1rem;
  border-radius: 0.7em;
  position: relative;
  box-shadow: 4px 4px 8px #b5ccd7;
  margin-bottom: 3rem;
	margin-right: 0;
	margin-left: auto;
	display: flex;
}
.dialogue-right span:after {
  transform: translatey(0px);
  content: ".";
  font-weight: bold;
  -webkit-text-fill-color: #fff;
  text-shadow: 2px 2px 4px #b5ccd7;
  text-align: right;
  font-size: 55px;
  width: 55px;
  height: 11px;
  line-height: 1rem;
  border-radius: 11px;
  background-color: #fff;
  position: absolute;
  display: block;
  bottom: -20px;
  right: 0;
  box-shadow: 2px 2px 4px #b5ccd7;
  z-index: -2;
}

.profile-pic {
	height: 2.5em;
  width: 2.5em;
}
.dialogue-right {
	position: relative;
}
.pic-right{
	right: -2.75em;
	bottom: -55px;
	position: absolute;
	z-index: 10;
}
.dialogue-left {
	position: relative;
}
.pic-left {
	left: -2.75em;
	bottom: -55px;
	position: absolute;
	z-index: 10;
}

.inline-icon {
  cursor: pointer;
  margin: 0.2em 1em 0 0;
  color: #546e78;
  display: inline-block !important;
}

.hidden-icon {
  margin: 0.2em 1em 0 0;
  display: none !important;
}